export default () => {
    if (process.browser) {
        (function () {
            const gtagScript = document.createElement('script');
            gtagScript.async = true;
            gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=TAG_ID`;
            document.head.appendChild(gtagScript);
        })();

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('set', 'linker',
            { 'domains':
                ['krystal.com', 'krystal.olo.com']
            }); 
        gtag('config', 'TAG_ID');
    }
}
