// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./IntegralCF-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./IntegralCF-DemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./IntegralCF-ExtraBold.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Integral CF Bold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-display:swap;font-style:normal}@font-face{font-family:\"Integral CF Demibold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-display:swap;font-style:normal}@font-face{font-family:\"Integral CF Extra\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-display:swap;font-style:normal}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
