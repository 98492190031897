//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
    mounted() {
        let elements = [{ 
            elem: '.footer-floating-hand',
            percentage: 70
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        // this.returnanimElements.bind(this, elements)
        // this.animElements.bind(this, elements)
    },
    unmounted () {
        let elements = [{ 
            elem: '.footer-floating-hand',
            percentage: 70
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        // this.returnanimElements(elements)
        // this.animElements.bind(this, elements)
    },
    computed: {
        ...mapState(['options', 'navMenus']),
        year() {
            return moment().year()
        },
        leftCount() {
            return Math.round(this.menu.length / 2)
        },
        menu() {
            return this.navMenus['footer-menu']
        },
        bottomMenu() {
            return this.navMenus['bottom-menu']
        },
        socialMenu() {
            return this.navMenus['social-menu']
        },
        mq() {
            return this.$mq
        }
    },
    methods: {
        isElementXPercentInViewport(el, percentVisible) {
            let rect = el.getBoundingClientRect(),
                windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;

            return !(
                Math.floor(
                    100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
                ) < percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
                percentVisible
            );
        },
        returnanimElements(elemntsAnim) {
            if (elemntsAnim.length > 0) {
                elemntsAnim.forEach((item) => {
                    var el = document.querySelectorAll(item.elem);
                    
                    if (el.length > 0) {
                        // window.addEventListener('load', (e) => {
                        el.forEach((element) => {
                            if (this.isElementXPercentInViewport(element, item.percentage)) {
                                element.classList.add("in_view");
                            } else {
                                element.classList.remove("in_view");
                            }
                        });
                    }
                });
            }
        },
    },
    data() {
        return {
            covidHidden: true
        }
    },
}
