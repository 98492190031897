//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState(['location', 'order', 'menu', 'loading', 'errors', 'showBanner'])
    },
    async mounted() {
        if (this.$route.path == '/' || 'home')
            try {
                // modal box
                const isSimpleDesign = false; // boolean, true if Roboto should be imported
                const boxBackgroundColor = "#FFF"; // string, ex. '#FFF';
                const boxFont = isSimpleDesign ? "'Roboto', sans-serif" : ""; // string, ex. 'Helvetica, Arial, sans-serif;';
                const boxMaxWidth = "380px"; // defaults to 370px, string, ex. '370px';
                const boxFontColor = ""; // string, ex. '#000';
                const closeBtnColor = "#FFF"; // string, ex. '#FFF';
                const closeBtnFilledLight = false; // boolean, true for overlay on close button
                const closeBtnFilledDark = true; // boolean, true if overlay on close button
                const boxBorderRadius = "10px"; // string, ex. '10px';
                const accessibilityActiveColor = "purple"; // string, ex. '#FFF';

                // image
                const includeImage = true; // boolean, ex. true (no quotes around true or false)
                const imageUrl =
                    "https://a.storyblok.com/f/120533/336x205/7cdf037313/krystal_site_menusignedinpage_4cheesekrystalcombo-purple-1.jpg";
                const imageAltTxt = ""; // string, ex. 'burger';, left empty for enhanced experience when on a screen reader
                const imageMaxHeight = "180px;"; // string, ex. '180px';
                const imageMinHeight = "150px;"; // string, ex. '140px';
                const imageObjectPosition = "50% 50%"; // string, ex. '0% 90%';

                // headline
                const headline = "Order Now"; // string, ex. 'Free Delivery';
                const headlineFontSize = "50px"; // string, ex. '64px';
                const headlineMobileFontSize = "50px"; // string, ex. '56px';
                const headlineUppercase = false; // boolean, ex. true (no quotes around true or false)
                const headlineFont = "evogria "; // string (defaults to box setting if empty), ex. '"Brixton Lead Vector", sans-serif;';
                const headlineFontColor = "#101820"; // string, ex. '#000';
                const headlineFontWeight = "300"; // string, ex. '700';

                // sub headline
                const subheadline = "Save time, order online"; // string, ex. 'Now through April 27th';
                const subheadlineFontSize = "20px"; // string, ex. '16px';
                const subheadlineMobileFontSize = "18px"; // string, ex. '14px';
                const subheadlineUppercase = false; // boolean, ex. true (no quotes around true or false)
                const subheadlineFont = "montserrat"; // string (defaults to box setting if empty), ex. '"Brixton Lead Vector", sans-serif;';
                const subheadlineFontColor = "#101820"; // string, ex. '#000';
                const subheadlineFontWeight = ""; // string, ex. '400';

                var locationSelected;
                document.querySelector("header.shadow span.has-address")
                    ? (locationSelected = true)
                    : (locationSelected = false);

                // cta buttons
                const singleButton = false; // false if two buttons are used, boolean, ex. true (no quotes around true or false)
                const doubleBtnOneTxt = "Delivery"; // string, ex. 'Delivery';
                const doubleBtnTwoTxt = "Pickup"; // string, ex. 'Pickup';
                const singleBtnTxt = "Order Now"; // string, ex. 'Order Now';
                const doubleBtnOneUrl = locationSelected
                    ? "/order/"
                    : "/locations/?delivery=true"; // string, ex. 'https://order.example.com/';
                const doubleBtnTwoUrl = locationSelected ? "/order/" : "/locations/"; // string, ex. 'https://order.example.com/';
                const singleBtnUrl = "#"; // string, ex. 'https://order.example.com/';
                const isVerticalLayout = false; // false if buttons are horizontal, boolean, ex. true (no quotes around true or false)
                const doubleVerticalBtnOneTxt = "Order Delivery"; // string, ex. 'Delivery';
                const doubleVerticalBtnTwoTxt = "Order Pickup"; // string, ex. 'Pickup';
                const btnFontSize = "18px"; // string, ex. '16px';
                const btnUppercase = true; // boolean, ex. true (no quotes around true or false)
                const btnFont = "evogria"; // string (defaults to box setting if empty), ex. '"Brixton Lead Vector", sans-serif;';
                const btnFontColor = "#FFF"; // string, ex. '#FFF';
                const btnFontWeight = "400"; // string, ex. '400';
                const btnBackgroundColor = "#da291c"; // string, ex. '#b11b1e';
                const btnBorderColor = "#da291ce"; // string, ex. '#b11b1e';
                const btnPadding = "10px 22px;"; // string, ex. 10px 22px;
                const btnHoverFontColor = "white"; // string, ex. '#FFF';
                const btnHoverBackgroundColor = "black"; // string, ex. '#769ca3';
                const btnBorderRadius = "3px"; // string, ex. '3px';

                // sub cta button
                const subButton = false; // true if sub buttons are used, boolean, ex. true (no quotes around true or false)
                const subBtnOneTxt = "Catering"; // string, ex. 'Catering';
                const subBtnOneUrl = "#"; // string, ex. 'https://order.example.com/';
                const subBtnHeadingTxt = "Ordering for a group?"; // string, ex. 'Ordering for a group?';
                const subBtnPadding = "8px 18px;"; // string, ex. 8px 18px;
                const subBtnFontSize = "16px"; // string, ex. '16px';

                // disclaimer
                const disclaimer = false; // false if there is no disclaimer, boolean, ex. true (no quotes around true or false)
                const disclaimerTxt =
                    "*This is my disclaimer, a very very very very very very very very very long disclaimer"; // string, ex. 'This is my disclaimer';
                const disclaimerFontSize = "10px"; // string, ex. '7px';
                const disclaimerFontColor = "#000";
                const disclaimerExpandable = false; // true if dislaimer should require expansion with "View Offer Details", boolean, ex. true (no quotes around true or false)
                const disclaimerExpandBtnFontSize = "12px"; // string, ex. '16px';

                // sticky
                const isSticky = false; // false if no sticky element, boolean, ex. true (no quotes around true or false)
                const directOrderStickyUrl = "#"; // use if isDirectOrderStickyLink is true, string, ex. 'https://order.example.com/';
                const isDirectOrderStickyLink = false; // false if sticky banner reopens modal
                const stickyHeadline = "Order Now"; // string, ex. 'Free Delivery';
                const stickyCloseBtnColor = "#FFF"; // string, ex. '#000';
                const stickyBackgroundColor = "#da291c"; // string, ex. '#000';
                const stickyBorderRadius = "3px"; // string, ex. '3px';
                const stickyFontSize = "20px"; // string, ex. '16px';
                const stickyFont = "evogria"; // string (defaults to box setting if empty), ex. '"Brixton Lead Vector", sans-serif;';
                const stickyFontWeight = "300"; // string, ex. '700';
                const stickyFontColor = "#FFF"; // string, ex. '#FFF';
                const stickyUppercase = true; // boolean, ex. true;

                // single day reoccuring
                const isSingleDayReoccuring = false; // false if always on, boolean, ex. true (no quotes around true or false)
                const daysForSingleDayReoccuring = ["tuesday"]; // must be in an array [], values can be 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', string, ex. ['sunday'];, ex. ['sunday','monday'];

                // start and end dates
                const startDate = ""; // leave as '' if no start date, string, ex. '05/22/2022';
                const endDate = ""; // leave as '' if no end date, string, ex. '05/27/2022';

                // create style element
                const style = document.createElement("style");

                // add CSS styles
                style.innerHTML = `
      ${isSimpleDesign
                        ? '@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400&display=swap");'
                        : ""
                    }
      .dwmodal {
        z-index: 100000;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
      }
      .dwmodal-box {
        z-index: 1000;
        position: absolute;
        top: 40%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        ${headlineFontColor ? `color: ${headlineFontColor};` : ""}
        ${boxFont ? `font-family: ${boxFont};` : ""}
        ${boxBackgroundColor ? `background-color: ${boxBackgroundColor};` : ""}
        ${includeImage ? "padding: 0 0 30px 0;" : "padding: 25px 0 30px 0;"}
        width: 93%;
        ${boxMaxWidth ? `max-width: ${boxMaxWidth};` : "max-width: 370px;"}
        ${boxBorderRadius
                        ? `border-radius: ${boxBorderRadius};`
                        : "border-radius: 10px;"
                    }
        /*background-image: linear-gradient(-86deg,#ececec 0,#fff 100%),linear-gradient(14deg,#000 0,#fff 100%);*/
        /*background-image: url(/wp-content/uploads/2016/08/halftone_white.jpg)!important;*/
        /*background-position: 50%;*/
        /*background-size: cover;*/
      }
      ${isSimpleDesign
                        ? `
        .dwmodal-headline,
        .dwmodal-sticky-headline,
        .dwmodal-subheadline,
        .dwmodal-order-btn,
        #dwmodal-disclaimer-text
        { 
          font-family: ${boxFont};
        }`
                        : ""
                    }
      .dwmodal-box-sticky {
        z-index: 100000;
        position: fixed;
        bottom: 25px;
        left: 25px;
        text-align: center;
        ${boxFont ? `font-family: ${boxFont};` : ""}
        ${stickyBackgroundColor ? `background-color: ${stickyBackgroundColor};` : ""}
        ${boxMaxWidth ? `max-width: ${boxMaxWidth};` : "max-width: 370px;"}
        ${stickyBorderRadius
                        ? `border-radius: ${stickyBorderRadius};`
                        : "border-radius: 3px;"
                    }
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
        transition: 0.3s;
        display: flex;
        align-items: center;
        padding: 7px 15px 7px 20px;
        opacity: 0;
        visibility: hidden;
        transform: skewX(-14deg);
      }
      .dwmodal-box-sticky:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,1);
      }
      .dwmodal-image {
        max-height: ${imageMaxHeight};
        min-height: ${imageMinHeight};
        width: 100%;
        border-style: none;
        ${boxBorderRadius
                        ? `border-top-left-radius: ${boxBorderRadius};`
                        : "border-top-left-radius: 10px;"
                    }
        ${boxBorderRadius
                        ? `border-top-right-radius: ${boxBorderRadius};`
                        : "border-top-right-radius: 10px;"
                    }
        object-fit: cover;
        ${imageObjectPosition ? `object-position: ${imageObjectPosition};` : ""}
      }
      #dwmodal-close-button:before {
        content: none !important;
      }
      #dwmodal-sticky-close-button:before {
        content: none !important;
      }
      #dwmodal-close-button,
      #dwmodal-sticky-close-button {
        width: 26px;
        height: 26px;
        text-align: center;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      #dwmodal-close-button {
        position: fixed;
        right: 10px;
        top: 10px;
        ${closeBtnFilledLight && !closeBtnFilledDark ? `background: #FFF;` : ""}
        ${!closeBtnFilledLight && closeBtnFilledDark ? `background: #000;` : ""}
        ${closeBtnFilledLight || closeBtnFilledDark ? `border-radius: 50px;` : ""}
      }
      #dwmodal-close-button:focus {
        ${accessibilityActiveColor
                        ? `outline: 2px solid ${accessibilityActiveColor};`
                        : "outline: 2px solid orange;"
                    }
      } 
      #dwmodal-sticky-close-button {
        position: relative;
        transform: skewX(14deg);
      }
      .dwmodal-headline {
        margin: 15px 2px 0 2px;
        padding: 0;
        line-height: 1.1;
        ${headlineFontSize ? `font-size: ${headlineFontSize};` : ""}
        ${headlineFontColor ? `color: ${headlineFontColor};` : ""}
        font-family: 'Integral CF Bold';
        ${headlineUppercase ? "text-transform: uppercase;" : ""}
        ${headlineFontWeight ? `font-weight: ${headlineFontWeight};` : ""}
        letter-spacing: 0px;
        padding-bottom: 0px;
      }
      .dwmodal-sticky-headline {
        ${stickyFontSize ? `font-size: ${stickyFontSize};` : ""}
        ${stickyFontColor ? `color: ${stickyFontColor};` : ""}
        ${stickyFont ? `font-family: ${stickyFont};` : ""}
        ${stickyUppercase ? "text-transform: uppercase;" : ""}
        ${stickyFontWeight ? `font-weight: ${stickyFontWeight};` : ""}
        padding: 0 10px 0 0;
        margin: 0;
        transform: skewX(14deg);
      }
      .dwmodal-sticky-link,
      .dwmodal-sticky-link:hover {
        text-decoration: none;
      }
      .dwmodal-subheadline {
        margin: 5px 15px 25px 15px;
        padding: 0;
        ${subheadlineFontSize ? `font-size: ${subheadlineFontSize};` : ""}
        ${subheadlineFontColor ? `color: ${subheadlineFontColor};` : ""}
        font-family: "Red Hat Mono", monospace;
        ${subheadlineUppercase ? "text-transform: uppercase;" : "text-transform: none"
                    }
        ${subheadlineFontWeight ? `font-weight: ${subheadlineFontWeight};` : ""}
      }
      .dwmodal-order-btn {
        ${btnBorderRadius ? `border-radius: ${btnBorderRadius};` : ""}
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        ${btnBackgroundColor ? `background-color: ${btnBackgroundColor};` : ""}
        ${btnBorderColor ? `border-color: ${btnBorderColor};` : ""}
        ${btnPadding ? `padding: ${btnPadding};` : ""}
        text-decoration: none;
        line-height: 1.2;
        box-shadow: 0px 3px 10px rgb(0 0 0 / 16%);
        transform: skewX(-14deg);
      }
      .dwmodal-order-btn:hover,
      .dwmodal-order-btn:active,
      .dwmodal-order-btn:visited,
      .dwmodal-order-btn:focus {
        text-decoration: none;
        ${btnFontColor ? `color: ${btnFontColor};` : ""}
      }
      .dwmodal-order-btn:focus {
        ${accessibilityActiveColor
                        ? `outline: 2px solid ${accessibilityActiveColor};`
                        : "outline: 2px solid orange;"
                    }
      }  
      .dwmodal-order-btn:hover {
        ${btnHoverFontColor ? `color: ${btnHoverFontColor};` : ""}
        ${btnHoverBackgroundColor
                        ? `background-color: ${btnHoverBackgroundColor};`
                        : ""
                    }
      }
      .dwmodal-order-btn:hover > span {
        ${btnHoverFontColor ? `color: ${btnHoverFontColor};` : ""}
        ${btnHoverBackgroundColor
                        ? `background-color: ${btnHoverBackgroundColor};`
                        : ""
                    }
      }
      .dwmodal-order-btn > span {
        ${btnFontSize ? `font-size: ${btnFontSize};` : ""}
        ${btnFontColor ? `color: ${btnFontColor};` : ""}
        ${btnFont ? `font-family: ${btnFont};` : ""}
        ${btnUppercase ? "text-transform: uppercase;" : ""}
        ${btnFontWeight ? `font-weight: ${btnFontWeight};` : ""}
        ${btnBackgroundColor ? `background-color: ${btnBackgroundColor};` : ""}
        ${btnBorderColor ? `border-color: ${btnBorderColor};` : ""}
        letter-spacing: 1px;
        transform: skewX(14deg);
      }
      .dwmodal-first-btn {
        margin-right: 7px;
      }
      .dwmodal-vertical-button-wrapper {
        width: 100%;
        padding: 0 10%;
      }
      .dwmodal-vertical-button-wrapper .dwmodal-first-btn {
        margin-right: 0px;
        margin-bottom: 7px;
      }
      .dwmodal-sub-button-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
      }
      .dwmodal-sub-button-wrapper span {
        margin-bottom: 5px;
      }
      .dwmodal-sub-btn {
        ${subBtnPadding ? `padding: ${subBtnPadding};` : ""}
        ${subBtnFontSize ? `font-size: ${subBtnFontSize};` : ""}
      }
      .dwmodal-disclaimer {
        margin: 25px 15px -20px 15px;
        text-align: left;
      }
      .dwmodal-disclaimer-button {
        text-decoration: underline;
        border: none;
        background: none;
        width: 100%;
        text-align: center;
        cursor: pointer;
        ${disclaimerExpandBtnFontSize
                        ? `font-size: ${disclaimerExpandBtnFontSize};`
                        : ""
                    }
        ${subheadlineFont ? `font-family: ${subheadlineFont};` : ""}
      }
      #dwmodal-disclaimer-text {
        ${disclaimerFontSize ? `font-size: ${disclaimerFontSize};` : ""}
        ${disclaimerFontColor ? `color: ${disclaimerFontColor};` : ""}
        line-height: 1.4;
        ${disclaimerExpandable ? "display: none;" : "display: block"}
      }
      .dwmodal-show-disclaimer-text {
        display: block !important;
      }
      .dwmodal-wrapper {
        display: flex;
        justify-content: center;
      }
      .dwmodal-show-modal {
        opacity: 1;
        visibility: visible;
        transform: scale(1.0)
      }
      .dwmodal-show-sticky-box {
        opacity: 1;
        visibility: visible;
        transform: scale(1.0) skewX(-14deg);
      }
      .dwmodal-hide-sticky-box {
        display: none;
      }
      #dwmodal-label-span {
        display: none;
      }
      @media only screen and (max-width: 600px) {
        .dwmodal-headline {
          ${headlineMobileFontSize ? `font-size: ${headlineMobileFontSize};` : ""}
        }
        .dwmodal-subheadline {
          ${subheadlineMobileFontSize
                        ? `font-size: ${subheadlineMobileFontSize};`
                        : ""
                    }
        }
        .dwmodal-box-sticky {
          bottom: 15px;
          left: 10px;
        }  
      }
      @media only screen and (max-height: 700px) {
        .dwmodal-box {
          top: 50%;
        }
      }
      .acsb-trigger-high {
        inset: auto auto 70px 20px !important;
      }
        `;

                // append the style to the DOM in <head> section
                document.head.appendChild(style);

                const redHatFont = document.createElement("style");
                redHatFont.innerHTML = '@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300..700;1,300..700&display=swap");' 
                document.head.appendChild(redHatFont);

                // time checks for single day reoccuring, start date, end date
                var inDayRange = false;
                var inTimeRange = false;
                const now = new Date();

                if (isSingleDayReoccuring && daysForSingleDayReoccuring) {
                    const days = [
                        "sunday",
                        "monday",
                        "tuesday",
                        "wednesday",
                        "thursday",
                        "friday",
                        "saturday",
                    ];
                    var singleDayReoccuringDayIndexes = [];
                    daysForSingleDayReoccuring.forEach((element) => {
                        singleDayReoccuringDayIndexes.push(days.indexOf(element.toLowerCase()));
                    });
                    const dateDay = now.getDay();
                    if (singleDayReoccuringDayIndexes.includes(dateDay)) {
                        inDayRange = true;
                    } else {
                        inDayRange = false;
                    }
                } else {
                    inDayRange = true;
                }

                if (startDate && !endDate) {
                    const startDateDateTime = new Date(startDate);
                    if (now > startDateDateTime) {
                        inTimeRange = true;
                    } else {
                        inTimeRange = false;
                    }
                } else if (startDate && endDate) {
                    const startDateDateTime = new Date(startDate);
                    const endDateDateTime = new Date(endDate + " 23:59:00");
                    if (now > startDateDateTime && now < endDateDateTime) {
                        inTimeRange = true;
                    } else {
                        inTimeRange = false;
                    }
                } else if (!startDate && endDate) {
                    const endDateDateTime = new Date(endDate + " 23:59:00");
                    if (now < endDateDateTime) {
                        inTimeRange = true;
                    } else {
                        inTimeRange = false;
                    }
                } else if (!startDate && !endDate) {
                    inTimeRange = true;
                }

                // page path checks
                const pageHref = window.location.href;
                var passContainsPagePath = false;
                var passContainsRegexPath = false;

                if (!excludeContainsPath && !includeContainsPath) {
                    passContainsPagePath = true;
                } else if (excludeContainsPath && !includeContainsPath) {
                    passContainsPagePath = !pageHref.includes(excludeContainsPath);
                } else if (!excludeContainsPath && includeContainsPath) {
                    passContainsPagePath = pageHref.includes(includeContainsPath);
                } else if (excludeContainsPath && includeContainsPath) {
                    passContainsPagePath =
                        !pageHref.includes(excludeContainsPath) &&
                        pageHref.includes(includeContainsPath);
                }

                const excludeContainsPath = ""; // string, ex. '/menu/restaurant/';
                const includeContainsPath = ""; // string, ex. '/menu/';
                const globalRegexPath = "^((?!(catering|job|career)).)*$"; // string of global regex, ex. 'menu|locations' to include strings, or '^((?!(catering|legal|apply\=1)).)*$' to exclude strings;

                if (globalRegexPath) {
                    let newGlobalRegexPath = new RegExp(globalRegexPath, "g");
                    passContainsRegexPath = newGlobalRegexPath.test(pageHref);
                } else {
                    passContainsRegexPath = true;
                }

                // html
                var body = document.getElementsByTagName("BODY")[0];
                body.insertAdjacentHTML(
                    "afterbegin",
                    `${isSticky
                        ? `<div aria-hidden="true" class="dwmodal-box-sticky">${isDirectOrderStickyLink
                            ? `<a class="dwmodal-sticky-link" href="${directOrderStickyUrl}"><p class="dwmodal-sticky-headline">${stickyHeadline}</p></a>`
                            : `<a class="dwmodal-sticky-link" href="#"><p class="dwmodal-sticky-headline">${stickyHeadline}</p></a>`
                        }<button id="dwmodal-sticky-close-button"><svg alt="close" aria-label="close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="${stickyCloseBtnColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div>`
                        : ``
                    }
        <div aria-hidden="true" class="dwmodal">
        <div class="dwmodal-box" role="alertdialog" aria-modal="true" aria-labelledby="dwmodal-label-span dwmodal-dialog-label" aria-describedby="dwmodal-dialog-desc"><span id="dwmodal-label-span">something special from us</span>${includeImage
                        ? `<div><img src="${imageUrl}" alt="${imageAltTxt}" class="dwmodal-image"></img></div>`
                        : `<div>`
                    }<h2 id="dwmodal-dialog-label" class="dwmodal-headline">${headline}</h2><h3 id="dwmodal-dialog-desc" class="dwmodal-subheadline">${subheadline}</h3><div class="dwmodal-wrapper">${singleButton
                        ? `<a class="dwmodal-order-btn" aria-describedby="dwmodal-disclaimer-text" href="${singleBtnUrl}">${singleBtnTxt}</a>`
                        : `${isVerticalLayout
                            ? `<div class="dwmodal-vertical-button-wrapper"><a class="dwmodal-order-btn dwmodal-first-btn" aria-describedby="dwmodal-disclaimer-text" href="${doubleBtnOneUrl}">${doubleVerticalBtnOneTxt}</a><a class="dwmodal-order-btn" aria-describedby="dwmodal-disclaimer-text" href="${doubleBtnTwoUrl}">${doubleVerticalBtnTwoTxt}</a></div>`
                            : `<a class="dwmodal-order-btn dwmodal-first-btn" aria-describedby="dwmodal-disclaimer-text" href="${doubleBtnOneUrl}">
                      <span>${doubleBtnOneTxt}</span>
                    </a>
                    <a class="dwmodal-order-btn" aria-describedby="dwmodal-disclaimer-text" href="${doubleBtnTwoUrl}">
                      <span>${doubleBtnTwoTxt}</span>
                    </a>`
                        }`
                    }</div>${subButton
                        ? `<div class="dwmodal-sub-button-wrapper"><span>${subBtnHeadingTxt}</span><a class="dwmodal-order-btn dwmodal-sub-btn" aria-describedby="dwmodal-disclaimer-text" href="${subBtnOneUrl}">${subBtnOneTxt}</a></div>`
                        : ""
                    }${disclaimer
                        ? `<div class="dwmodal-disclaimer">${disclaimerExpandable
                            ? `<button class="dwmodal-disclaimer-button">View Offer Details</button>`
                            : ""
                        }<span id="dwmodal-disclaimer-text">${disclaimerTxt}</span></div>`
                        : ""
                    }<button aria-label="close ${headline.toLowerCase()}" id="dwmodal-close-button"><svg alt="close" aria-label="close" xmlns="http://www.w3.org/2000/svg" width="${closeBtnFilledLight || closeBtnFilledDark ? "20" : "25"
                    }" height="${closeBtnFilledLight || closeBtnFilledDark ? "20" : "25"
                    }" viewBox="0 0 24 24" fill="none" stroke="${closeBtnFilledLight ? "#000" : closeBtnColor
                    }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></div>
        </div>`
                );

                // display logic
                var modal = document.querySelector(".dwmodal");
                var modalBox = document.querySelector(".dwmodal-box");
                var closeButton = document.querySelector("#dwmodal-close-button");
                var a11yButton = document.querySelector(".acsb-trigger");
                var stickyBox = document.querySelector(".dwmodal-box-sticky");
                var stickyBoxHeadline = document.querySelector(".dwmodal-sticky-headline");
                var stickyBoxCloseButton = document.querySelector(
                    "#dwmodal-sticky-close-button"
                );
                var disclaimerExpandButton = document.querySelector(
                    ".dwmodal-disclaimer-button"
                );
                var disclaimerExpandText = document.querySelector("#dwmodal-disclaimer-text");

                function toggleModal(sticky) {
                    modal.classList.toggle("dwmodal-show-modal");
                    modal.ariaHidden == "true"
                        ? (modal.ariaHidden = "false")
                        : (modal.ariaHidden = "true");
                    modalBox.focus();
                    sessionStorage.setItem("modalFired", "true");
                    isSticky && sticky && toggleStickyBox();
                }
                function closeStickyBox() {
                    stickyBox.classList.toggle("dwmodal-hide-sticky-box");
                    a11yButton.classList.toggle("acsb-trigger-high");
                    stickyBox.ariaHidden = "true";
                    sessionStorage.setItem("stickyBoxClosed", "true");
                }
                function toggleStickyBox() {
                    stickyBox.classList.toggle("dwmodal-show-sticky-box");
                    a11yButton.classList.toggle("acsb-trigger-high");
                    stickyBox.ariaHidden == "true"
                        ? (stickyBox.ariaHidden = "false")
                        : (stickyBox.ariaHidden = "true");
                }
                function toggleDisclaimer() {
                    disclaimerExpandText.classList.toggle("dwmodal-show-disclaimer-text");
                }
                function windowOnClick(event) {
                    if (event.target === modal) {
                        toggleModal(true);
                    }
                }
                closeButton.addEventListener("click", () => toggleModal(true));
                isSticky &&
                    !isDirectOrderStickyLink &&
                    stickyBoxHeadline.addEventListener("click", toggleModal);
                isSticky && stickyBoxCloseButton.addEventListener("click", closeStickyBox);
                window.addEventListener("click", windowOnClick);
                disclaimer &&
                    disclaimerExpandable &&
                    disclaimerExpandButton.addEventListener("click", () => toggleDisclaimer());
                if (
                    !sessionStorage.getItem("modalFired") &&
                    !sessionStorage.getItem("stickyBoxClosed") &&
                    inTimeRange &&
                    inDayRange &&
                    passContainsPagePath &&
                    passContainsRegexPath
                ) {
                    toggleModal(false);
                } else if (
                    sessionStorage.getItem("modalFired") &&
                    !sessionStorage.getItem("stickyBoxClosed") &&
                    inTimeRange &&
                    inDayRange &&
                    passContainsPagePath &&
                    passContainsRegexPath
                ) {
                    toggleStickyBox();
                }

                // accessibility
                const focusableElements =
                    'button, [href], a, [tabindex]:not([tabindex="-1"])';
                const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
                const secondFocusableElement = modal.querySelectorAll(focusableElements)[1];
                const focusableContent = modal.querySelectorAll(focusableElements);
                const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
                //let tabCounter = 0;

                document.addEventListener("keydown", function (e) {
                    let isTabPressed = e.key === "Tab" || e.code === "Tab";
                    let isEscPressed = e.key === "Escape" || e.code === "Escape";
                    if (isEscPressed) {
                        toggleModal();
                        return;
                    } else if (!isTabPressed) {
                        return;
                    }

                    if (e.shiftKey) {
                        // if shift key pressed for shift + tab combination
                        if (document.activeElement === firstFocusableElement) {
                            e.preventDefault();
                            lastFocusableElement.focus(); // add focus for the last focusable element
                        }
                    } else {
                        if (document.activeElement === lastFocusableElement) {
                            // if focused has reached to last focusable element then focus first focusable element after pressing tab
                            e.preventDefault();
                            firstFocusableElement.focus(); // add focus for the first focusable element
                        }
                    }
                });
            } catch (err) {
                console.log(err)
            }

        try {
            await this.$recaptcha.init()
        } catch (err) {
            console.error(err)
        }
        if (this.$route.query.store) {
            await this.$api.getLocation(this.$route.query.store)
            await this.$api.getMenu(true, false)
            if (this.order && this.order.menu_id != this.location.menu_id) {
                await this.$api.transferOrder()
            }
        } else if (this.$route.query.menu_id) {
            await this.$api.getLocation(this.$route.query.menu_id, true)
            await this.$api.getMenu(true, false)
            if (this.order && this.order.menu_id != this.location.menu_id) {
                await this.$api.transferOrder()
            }
        }
        if (this.location) {
            await this.$api.getMenu(true, false)
        }
        // if (this.$mq !== 'sm' && this.$mq !== 'md') {
        //   // this.$store.commit('setShowNav', true)
        // } else {
        //   this.$store.commit('setShowNav', false)
        // }
        window.addEventListener('resize', this.resize)
        this.$nextTick(() => {
            this.resize()
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.resize)
    },
    methods: {
        ...mapActions(['fanFave']),
        resize() {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            if (vw >= 1025) {
                this.$store.commit('setShowNav', true)
            } else {
                this.$store.commit('setShowNav', false)
            }
            this.$store.commit('setWidth', vw)
        }
    },
    watch: {
        '$route.path'(e) {
            this.$store.commit('setErrors', [])
            if (this.$mq == 'sm' || this.$mq == 'md') {
                this.$store.commit('setShowNav', false)
            }
            window.scroll({ top: 0, left: 0, behavior: 'smooth' }) // scroll to top on route change
        }
    }
}
