import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31186d84 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _3e7eb8b4 = () => interopDefault(import('../pages/menu/product/_sku.vue' /* webpackChunkName: "pages/menu/product/_sku" */))
const _632bce3a = () => interopDefault(import('../pages/promos/_path.vue' /* webpackChunkName: "pages/promos/_path" */))
const _3d4f3618 = () => interopDefault(import('../pages/locations/_.vue' /* webpackChunkName: "pages/locations/_" */))
const _b186cf36 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _31186d84,
    name: "404"
  }, {
    path: "/menu/product/:sku?",
    component: _3e7eb8b4,
    name: "menu-product-sku"
  }, {
    path: "/promos/:path?",
    component: _632bce3a,
    name: "promos-path"
  }, {
    path: "/locations/*",
    component: _3d4f3618,
    name: "locations-all"
  }, {
    path: "/*",
    component: _b186cf36,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
