/* DO NOT EDIT OUTSIDE OF MASTER */

export const state = () => ({
  data: null,
  quantity: 1,
  cost: 0,
  modifiers: [],
  addedToCart: false
})

export const mutations = {
  setData(state, obj) {
    state.data = obj
  },

  setQuantity(state, int) {
    state.quantity = int
  },

  setCost(state, float) {
    state.cost = float
  },

  setModifiers(state, array) {
    state.modifiers = array
  },

  setAddedToCart(state, bool) {
    state.addedToCart = bool
  }
}

export const actions = {
  async getProduct({ commit, dispatch }, data) {
    let product = await this.$api.getProduct(data.id)
    if(product) {
      commit('setData', product)
      let mods = await dispatch('setDefaults', {
        modifiers: product.modifiers,
        defaults: data.defaults,
        quantities: data.quantities
      })
      commit('setModifiers', mods)
      dispatch('updateCost')
    }
  },
  clearProduct({ commit, dispatch }) {
    commit('setData', null)
  },
  async setDefaults({ dispatch }, data) {
    const mods = JSON.parse(JSON.stringify(data.modifiers))
    mods.map(async m => {
      m.options.map(async o => {
        let indx = data.defaults.indexOf(o.id)
        if(m.groupname) {
          o.selected = true
        } else if(data.defaults.length > 0 && indx > -1) {
          o.selected = data.quantities[indx]
        } else if(data.defaults.length < 1 && o.default) {
          o.selected = true
        }
        if(o.modifiers) {
          o.modifiers = await dispatch('setDefaults', {
            modifiers: o.modifiers,
            defaults: data.defaults,
            quantities: data.quantities
          })
        }
      })
    })
    return mods
  },
  async updateCost({ commit, dispatch }) {
    const getModifierCost = (cost, mods) => {
      mods.map(m => {
        m.options.map(o => {
          if(o.selected) {
            cost += o.cost * o.selected
  
            if(o.modifiers) {
              cost = getModifierCost(cost, o.modifiers)
            }
          }
        })
      })
      return cost
    }
    let cost = getModifierCost(this.state.product.data.cost, this.state.product.modifiers)
    commit('setCost', cost)
  },
  async addModifier({ commit, dispatch }, data) {
    const activateModifier = (mods, mid, oid, q) => {
      mods.map(m => {
        if(m.id == mid) {
          const s = m.selects
          m.options.map(o => {
            if(s.mandatory && !s.min && !s.max) {
              if(o.id == oid) {
                o.selected = true
              } else {
                o.selected = false
              }
            } else if(o.id == oid && !s.min && !s.max) {
              o.selected = !o.selected
            } else if(o.id == oid) {
              let totals = 0
              m.options.map(p => {
                if(p.selected) {
                  totals += p.selected
                }
              })
              if(
                (q < 0 && totals > s.min && typeof o.selected == 'number')
                || (q > 0 && totals < s.max)
              ) {
                if(!o.selected && q > 0) {
                  o.selected = 1
                } else if(o.selected > 0) {
                  o.selected += q
                }
              }
            }
          })
        }
        m.options.map(async o => {
          if(o.modifiers) {
            o.modifiers = activateModifier(o.modifiers, mid, oid, q)
          }
        })
      })
      return mods
    }
    let modifiers = JSON.parse(JSON.stringify(this.state.product.modifiers))
    modifiers = activateModifier(modifiers, data.mid, data.oid, data.q)
    commit('setModifiers', modifiers)
    dispatch('updateCost')
  },
  decreaseQuantity({ commit }) {
    if(this.state.product.quantity > 1) {
      commit('setQuantity', this.state.product.quantity - 1)
    }
  },
  increaseQuantity({ commit }) {
    if(this.state.product.quantity < 99) {
      commit('setQuantity', this.state.product.quantity + 1)
    }
  },
  async addToCart({ commit }, data) {
    const checkModifiers = (errors, mods) => {
      mods.map(m => {
        const s = m.selects
        let selected = 0
        m.options.map(o => {
          if(o.selected) {
            selected += 1
            if(o.modifiers) {
              errors = checkModifiers(errors, o.modifiers)
            }
          }
        })
        if(s.mandatory) {
          if(!s.min && !s.max && selected != 1) {
            errors.push(`No selection made for ${m.name}.`)
          } else {
            if(s.min && selected < s.min) {
              errors.push(`Minimum ${s.min} required for ${m.name} (you have ${selected}).`)
            }
            if(s.max && selected > s.max) {
              errors.push(`Maximum ${s.max} required for ${m.name} (you have ${selected}).`)
            }
          }
        }
      })
      return errors
    }
    const addChoicesToProduct = (choices, mods) => {
      mods.map(m => {
        m.options.map(o => {
          if(o.selected) {
            choices.push({
              id: o.id,
              quantity: typeof o.selected === 'number' ? o.selected : 1
            })
            if(o.modifiers) {
              choices = addChoicesToProduct(choices, o.modifiers)
            }
          }
        })
      })
      console.log(choices)
      return choices
    }
    const errors = checkModifiers([], this.state.product.modifiers)
    if(errors.length == 0) {
      const product = {
        id: data.id,
        quantity: this.state.product.quantity,
        choices: addChoicesToProduct([], this.state.product.modifiers)
      }
      let result = null
      if(data.update) {
        product.id = data.cartId
        result = await this.$api.updateInOrder([product])
      } else {
        result = await this.$api.addToOrder([product])
      }
      return result
    } else {
      commit('setErrors', errors, { root: true })
      return false
    }
  }
}