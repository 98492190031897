//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'showNav' ])
  },
  methods: {
    toggleNav() {
      this.$store.commit('setShowNav', !this.showNav)
    }
  }
}
