export default (context) => {
    /* function getCookieValue(name, req) {
        let cookieString = '';
    
        if (process.client) {
            cookieString = document.cookie;
        } 

        else if (process.server && req && req.headers.cookie) {
            cookieString = req.headers.cookie;
        }

        const value = `; ${cookieString}`;
        const parts = value.split(`; ${name}=`);
    
        if (parts.length === 2) {
            return `&_gl=${parts.pop().split(';').shift()}`;
        }
    
        return null;
    }

    const gaCookie = getCookieValue('_ga', context.req); */

    // let STAGGERED_ROLLOUT = 'MCM001,NTN001,JCM008,CLV001,KNX015,KNXO20,MNT009,CDL001,CDR001,WRG003,NSH015,ATL047'
    let STAGGERED_ROLLOUT = process.env.STAGGERED_ROLLOUT

    if (STAGGERED_ROLLOUT) {

        let staggered_locations_arr = STAGGERED_ROLLOUT.split(',')
        console.log('context.store.state.location:::: ', context.store.state.location)
        if (staggered_locations_arr && context.store.state.location) {

            if (staggered_locations_arr.includes(context.store.state.location.name)) { // if location is in the array
                console.log('we have a staggered location!!!!!!!!!!!!!!!!!!!')

                if (context.route && context.route.path.toString().includes('/order')) {
                    if (context.route.path.toString().includes('/product')) {
                        if (context.store.state.location) {
                            console.log('store.state.location.slug::: ', context.store.state.location.slug)
                            if (context.store.state.location.slug) {
                                return context.redirect(`${context.store.state.location.slug}products/${context.route.query?.id}/?stay=y`) // ${gaCookie}
                            }
                        }
                    } else {
                        if (context.store.state.location) {
                            if (context.store.state.location.slug) {
                                return context.redirect(`${context.store.state.location.slug}?stay=y`) // ${gaCookie}
                            }
                        } else {
                            return context.redirect('/')
                        }
                    }
                }
            
            
                if (context.route && context.route.path.toString().includes('/account')) {
                    console.log('we are in an account route')
                    if (process.browser.window) {
                        console.log('process.browser:::: ', process.browser)
                        process.browser.window.onNuxtReady(() => { process.browser.window.$nuxt.$router.push('/') })
                    } else {
                        console.log('no proccess browser here')
                        return context.redirect(302, '/')
                    }      
                }
                if (context.route && context.route.path.toString().includes('/checkout')) {
                    console.log('we are in an checkout route')
                    if (process.browser.window) {
                        process.browser.window.onNuxtReady(() => { process.browser.window.$nuxt.$router.push('/') })
                    } else {
                        return context.redirect(302, '/')
                    }
                }
            } else {
                console.log('just a normal boring location!')
            }
            
        }
    }
    

    

}
